<template>
  <FormattedMessage class="block" :definition="translations.refund">
    <template #amount>
      <span v-if="price" class="body-1-bold">
        {{ i18n.price(price) }}
      </span>
      <span v-else class="body-1-bold">{{ i18n(translations.noRefund) }}</span>
    </template>
  </FormattedMessage>
</template>

<script setup lang="ts">
import type { Price } from '@backmarket/http-api'
import FormattedMessage from '@backmarket/nuxt-module-i18n/FormattedMessage.vue'
import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'

import translations from './PolicyRefundPrice.translations'

defineProps<{
  price: Price | null
}>()

const i18n = useI18n()
</script>
