<template>
  <RevCheckbox
    :id="policy.partnerName"
    :key="policy.id"
    :model-value
    :name="policy.id"
    variant="full"
    @update:model-value="(value) => emit('update:modelValue', value)"
  >
    <template #label>{{ policy.offerTitle }}</template>

    <template #description>
      <ul>
        <li v-if="hasCoverage('DAMAGE')">{{ i18n(translations.damage) }}</li>
        <li v-if="hasCoverage('WARRANTY_EXTENSION')">
          {{ i18n(translations.warrantyExtension) }}
        </li>
        <li v-if="hasCoverage('THEFT')">{{ i18n(translations.theft) }}</li>
      </ul>

      <div class="space-y-16">
        <p v-if="endDate && policy.state !== 'CREATED'">
          {{ i18n(translations.coveredUntil, { date: new Date(endDate) }) }}
        </p>

        <p
          v-if="
            policy.withdrawalPeriodEndDate &&
            isFuture(policy.withdrawalPeriodEndDate)
          "
          class="body-2"
        >
          {{
            i18n(translations.withdrawableUntil, {
              date: new Date(policy.withdrawalPeriodEndDate),
            })
          }}
        </p>

        <p v-if="policy.hasPendingTerminationRequest" class="body-2-bold">
          {{ i18n(translations.cancellationInProgress) }}
        </p>
      </div>
    </template>
  </RevCheckbox>
</template>

<script setup lang="ts">
import { computed } from 'vue'

import type {
  CoverageKind,
  InsurancePolicy,
} from '@backmarket/http-api/src/api-models'
import type { DateDate } from '@backmarket/http-api/src/standards'
import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { RevCheckbox } from '@ds/components/Checkbox'

import translations from './PolicyCheckbox.translations'

const i18n = useI18n()

const props = defineProps<{ policy: InsurancePolicy; modelValue: boolean }>()
const emit = defineEmits(['update:modelValue'])

function hasCoverage(coverageKind: CoverageKind) {
  return props.policy.coverages.some(
    (coverage) => coverage.kind === coverageKind,
  )
}

function isFuture(date: DateDate) {
  return new Date(date).getTime() > new Date().getTime()
}

const endDate = computed(() => props.policy.coverages[0].endDate)
</script>
