<template>
  <RevCheckbox id="defect-warranty" disabled variant="full">
    <template #label>{{ i18n(translations.label) }}</template>

    <template #description>
      <p>
        {{
          i18n(translations.coveredUntil, {
            date: new Date(defectWarranty.endDate),
          })
        }}
      </p>

      <p class="uppercase">{{ i18n(translations.free) }}</p>
    </template>
  </RevCheckbox>
</template>

<script setup lang="ts">
import { computed } from 'vue'

import type { BackmarketWarranty, MerchantWarranty } from '@backmarket/http-api'
import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { RevCheckbox } from '@ds/components/Checkbox'

import translations from './DefectWarrantyCheckbox.translations'

const i18n = useI18n()

const props = defineProps<{
  backmarketWarranty: BackmarketWarranty | null
  merchantWarranty: MerchantWarranty
}>()

const defectWarranty = computed(() => {
  if (
    props.backmarketWarranty &&
    props.backmarketWarranty.duration > props.merchantWarranty.duration
  ) {
    return props.backmarketWarranty
  }

  return props.merchantWarranty
})
</script>
