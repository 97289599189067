<template>
  <div class="space-y-24">
    <header>
      <h2 class="body-2">
        {{ i18n(translations.heading2) }}
      </h2>

      <h1 class="heading-1">
        {{ i18n(translations.heading1) }}
      </h1>
    </header>

    <section class="space-y-48">
      <div class="space-y-16">
        <p>{{ i18n(translations.requestSent) }}</p>

        <p>
          <FormattedMessage :definition="translations.confirmationMail">
            <template #amount>
              <span class="body-1-bold">{{ i18n.price(refundedPrice) }}</span>
            </template>
          </FormattedMessage>
        </p>

        <p>{{ i18n(translations.wireTransfer) }}</p>
      </div>

      <RevButton
        :to="
          resolveLocalizedRoute({ name: ROUTE_NAMES.DASHBOARD.MY_ORDERS.SELF })
        "
        variant="primary"
      >
        {{ i18n(translations.goBack) }}
      </RevButton>
    </section>
  </div>
</template>

<script setup lang="ts">
import { useRouteLocationWithLocale } from '#imports'

import type { MonetaryAmount } from '@backmarket/http-api'
import FormattedMessage from '@backmarket/nuxt-module-i18n/FormattedMessage.vue'
import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { RevButton } from '@ds/components/Button'

import { ROUTE_NAMES } from '@/scopes/dashboard/constants'

import translations from './RefundConfirmation.translations'

const resolveLocalizedRoute = useRouteLocationWithLocale()

const i18n = useI18n()

defineProps<{
  refundedPrice: MonetaryAmount
}>()
</script>
