export default {
  refund: {
    id: 'dashboard_cancel_insurances_refund_mention',
    defaultMessage: "You'll be refunded of {amount, html}.",
  },
  noRefund: {
    id: 'dashboard_cancel_insurances_no_refund',
    defaultMessage: 'No refund',
  },
}
