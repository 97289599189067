import type { I18nDefinition } from '@backmarket/nuxt-module-i18n/types'

const translations: Record<string, I18nDefinition> = {
  myOrders: {
    id: 'dashboard_cancel_my_orders_route_title',
    defaultMessage: 'My orders',
  },
  insurancesWithdraw: {
    id: 'dashboard_cancel_insurances_route_title',
    defaultMessage: 'Cancel my insurance',
  },
}

export default translations
