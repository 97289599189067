export default {
  confirmationMail: {
    id: 'dashboard_cancel_insurances_confirmation_mail_and_refund_amount',
    defaultMessage:
      "You will receive a confirmation email within a few days, then you'll be refunded of {amount, html}.",
  },
  goBack: {
    id: 'dashboard_cancel_order_back',
    defaultMessage: 'Go back to my orders',
  },
  heading1: {
    id: 'dashboard_cancel_insurances_confirmation_title',
    defaultMessage: 'Thank you!',
  },
  heading2: {
    id: 'dashboard_cancel_insurances_subtitle',
    defaultMessage: "Life without risks isn't life",
  },
  requestSent: {
    id: 'dashboard_cancel_insurances_request_sent',
    defaultMessage: 'Your request has been sent to the insurer.',
  },
  wireTransfer: {
    id: 'dashboard_cancel_insurances_confirmation_wire_transfer',
    defaultMessage:
      'Please note that the wire transfer may take a few days before appearing on your bank account.',
  },
}
