export default {
  label: {
    id: 'dashboard_cancel_insurances_defect_warranty',
    defaultMessage: 'Defect warranty',
  },
  free: {
    id: 'insurances_options_free',
    defaultMessage: 'Free',
  },
  coveredUntil: {
    id: 'dashboard_cancel_insurances_covered_until',
    defaultMessage: 'Covered until {date, date}',
  },
}
