<template>
  <Breadcrumb />

  <RevContainer>
    <RevCard class="p-56">
      <div class="flex flex-col gap-32 md:flex-row">
        <div class="flex items-center md:w-1/2">
          <div class="grow">
            <RefundConfirmation v-if="refundedPrice" :refunded-price />

            <PolicySelectionForm v-else @refunded="setRefundedAmount" />
          </div>
        </div>

        <div class="flex justify-center md:w-1/2">
          <div>
            <RevIllustration
              alt=""
              :height="301"
              src="/img/common/waving.svg"
              :width="315"
            />
          </div>
        </div>
      </div>
    </RevCard>
  </RevContainer>
</template>

<script setup lang="ts">
import { ref } from 'vue'

import type { MonetaryAmount } from '@backmarket/http-api'
import { RevCard } from '@ds/components/Card'
import { RevContainer } from '@ds/components/Container'
import { RevIllustration } from '@ds/components/Illustration'

import Breadcrumb from './components/Breadcrumb/Breadcrumb.vue'
import PolicySelectionForm from './components/PolicySelectionForm/PolicySelectionForm.vue'
import RefundConfirmation from './components/RefundConfirmation/RefundConfirmation.vue'

const refundedPrice = ref<MonetaryAmount | null>(null)

function setRefundedAmount(amount: MonetaryAmount) {
  refundedPrice.value = amount
}
</script>
