import { createHttpEndpoint } from '../../utils'

import type { InsuranceTermination } from './types/InsuranceTermination'

/**
 * Request a policy termination
 *
 * @see {@link https://devportal.backmarket.io/catalog/default/api/insurance-internal-v2-api/definition#operation/bm-insurances-v1-policies-termination-post}
 */
export const postInsuranceTerminationV1 = createHttpEndpoint({
  method: 'POST',
  operationId: 'withdrawInsurance',
  path: '/bm/insurances/v1/policies/:policyId/termination',
})

/**
 * Get Insurance Policy termination information
 *
 * @see {@link https://devportal.backmarket.io/catalog/default/api/insurance-internal-v2-api/definition#operation/bm-insurances-v2-policies-termination-get}
 */
export const getInsuranceTermination = createHttpEndpoint<InsuranceTermination>(
  {
    method: 'GET',
    operationId: 'getInsuranceTermination',
    path: '/bm/insurances/v2/policies/:policyId/termination',
  },
)

/**
 * Request a policy termination
 *
 * @see {@link https://devportal.backmarket.io/catalog/default/api/insurance-internal-v2-api/definition#operation/bm-insurances-v2-policies-termination-post}
 */
export const postInsuranceTerminationV2 = createHttpEndpoint({
  method: 'POST',
  operationId: 'getInsuranceTermination',
  path: '/bm/insurances/v2/policies/:policyId/termination',
})
