export default {
  damage: {
    id: 'dashboard_orders_insurance_coverage_damage',
    defaultMessage: 'Damage',
  },
  theft: {
    id: 'dashboard_orders_insurance_coverage_theft',
    defaultMessage: 'Theft',
  },
  warrantyExtension: {
    id: 'dashboard_orders_insurance_coverage_warranty_extension',
    defaultMessage: 'Warranty extension',
  },
  cancellationInProgress: {
    id: 'dashboard_cancel_insurances_cancellation_in_progress',
    defaultMessage: 'Cancellation in progress',
  },
  coveredUntil: {
    id: 'dashboard_cancel_insurances_covered_until',
    defaultMessage: 'Covered until {date, date}',
  },
  withdrawableUntil: {
    id: 'dashboard_cancel_insurances_withdrawable_until',
    defaultMessage: 'Not sure? You can cancel anytime before {date, date}',
  },
}
