<template>
  <div class="space-y-24">
    <header>
      <h2 class="body-2">
        {{ i18n(translations.heading2) }}
      </h2>

      <h1 class="heading-1">
        {{ i18n(translations.heading1) }}
      </h1>
    </header>

    <RevInfoBlock
      v-if="error"
      :cta="i18n(translations.tryAgain)"
      :icon="IconWarning"
      :title="i18n(translations.error)"
      variant="danger"
      @click="() => refresh()"
    />

    <div class="space-y-16">
      <DefectWarrantyCheckbox
        v-if="coverage"
        :backmarket-warranty="coverage.backmarketWarranty"
        :merchant-warranty="coverage.merchantWarranty"
      />

      <RevForm :initial-values @submit="withdraw">
        <template #default="{ values }">
          <div class="space-y-16">
            <PolicyCheckbox
              v-for="policy in withdrawablePolicies"
              :key="policy.id"
              v-model="values[policy.id]"
              :policy
            />

            <RevSkeleton
              v-if="pending"
              height="3.625rem"
              shape="rectangle"
              width="100%"
            />

            <PolicyRefundPrice
              v-if="getSelectedPolicies(values).length > 0"
              :price="getRefundPrice(values)"
            />

            <RevButton
              :disabled="
                isSubmitting || getSelectedPolicies(values).length === 0
              "
              :loading="isSubmitting"
              type="submit"
              variant="primary"
            >
              {{ i18n(translations.confirm) }}
            </RevButton>
          </div>
        </template>
      </RevForm>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useRoute } from '#imports'
import { computed, ref } from 'vue'

import { type InsurancePolicy } from '@backmarket/http-api'
import { getOrderlineWarranties } from '@backmarket/http-api/src/api-specs-after-sale-experience/customer-orders'
import { postInsuranceTerminationV1 } from '@backmarket/http-api/src/api-specs-insurance/insurances2/insurances2'
import { $httpFetch } from '@backmarket/nuxt-module-http/$httpFetch'
import { useHttpFetch } from '@backmarket/nuxt-module-http/useHttpFetch'
import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { useTheToast } from '@backmarket/nuxt-module-toast/useTheToast'
import { RevButton } from '@ds/components/Button'
import { RevForm } from '@ds/components/Form'
import { RevInfoBlock } from '@ds/components/InfoBlock'
import { RevSkeleton } from '@ds/components/Skeleton'
import { IconWarning } from '@ds/icons/IconWarning'

import translations from './PolicySelectionForm.translations'
import DefectWarrantyCheckbox from './components/DefectWarrantyCheckbox/DefectWarrantyCheckbox.vue'
import PolicyCheckbox from './components/PolicyCheckbox/PolicyCheckbox.vue'
import PolicyRefundPrice from './components/PolicyRefundPrice/PolicyRefundPrice.vue'

const i18n = useI18n()
const route = useRoute()
const { openErrorToast } = useTheToast()

const {
  data: coverage,
  error,
  refresh,
  pending,
} = useHttpFetch(getOrderlineWarranties, {
  pathParams: { orderlineId: route.params.orderlineId },
})

const isSubmitting = ref(false)
const emit = defineEmits(['refunded'])

type FormValues = Record<number | string, boolean>

const withdrawablePolicies = computed(() => {
  if (!coverage.value) return []

  return coverage.value.insurancePolicies.filter((policy) => {
    return (
      policy.isWithdrawable &&
      policy.state !== 'TERMINATED' &&
      !policy.hasPendingTerminationRequest
    )
  })
})

const initialValues = computed<FormValues>(() => {
  if (!withdrawablePolicies.value) return {}

  const isSelected = withdrawablePolicies.value.length === 1

  return withdrawablePolicies.value.reduce(
    (acc, policy) => ({ ...acc, [policy.id]: isSelected }),
    {},
  )
})

function getSelectedPolicies(values: FormValues) {
  if (!withdrawablePolicies.value) return []

  return withdrawablePolicies.value.filter((policy) => values[policy.id])
}

function getRefundPrice(values: FormValues) {
  const selectedPolicies = getSelectedPolicies(values)

  const refundAmount = selectedPolicies.reduce(
    (totalAmount: number | null, policy: InsurancePolicy) => {
      if (policy.priceToRefund !== null) {
        if (totalAmount === null) {
          return parseFloat(policy.priceToRefund.amount)
        }

        return totalAmount + parseFloat(policy.priceToRefund.amount)
      }

      return totalAmount
    },
    null,
  )

  if (refundAmount === null) {
    return null
  }

  return {
    amount: refundAmount.toFixed(2),
    currency: selectedPolicies[0].price.currency,
  }
}

async function withdraw(values: FormValues) {
  isSubmitting.value = true

  try {
    const selectedPolicies = getSelectedPolicies(values)

    await Promise.all(
      selectedPolicies.map((policy) =>
        $httpFetch(postInsuranceTerminationV1, {
          pathParams: { policyId: policy.id },
          body: {
            terminationKind: 'WITHDRAWAL',
          },
        }),
      ),
    )

    emit('refunded', getRefundPrice(values))
  } catch {
    openErrorToast()
  } finally {
    isSubmitting.value = false
  }
}
</script>
