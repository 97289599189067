import type { OrderlineCoverage } from '../../api-models'
import { createHttpEndpoint } from '../../utils'

/**
 * Retrieve warranties available for an orderline
 *
 * @see {@link https://devportal.backmarket.io/catalog/default/api/orders-mobile-api/definition#tag/orderline/operation/get-orderline-coverage}
 */
export const getOrderlineWarranties = createHttpEndpoint<OrderlineCoverage>({
  method: 'GET',
  operationId: 'getOrderlineWarranties',
  path: '/bm/orders/v1/orderline/:orderlineId/coverage',
})
