<template>
  <RevContainer>
    <RevBreadcrumb
      ariaLabel="breadcrumb"
      :breads="[
        {
          link: resolveLocalizedRoute({ name: ROUTE_NAMES.DASHBOARD.SELF }),
          title: i18n(translations.myOrders),
        },
        {
          link: resolveLocalizedRoute({
            name: ROUTE_NAMES.DASHBOARD.MY_ORDERS.WITHDRAW.INSURANCES,
          }),
          title: i18n(translations.insurancesWithdraw),
        },
      ]"
    />
  </RevContainer>
</template>

<script setup lang="ts">
import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { RevBreadcrumb } from '@ds/components/Breadcrumb'
import { RevContainer } from '@ds/components/Container'

import { useRouteLocationWithLocale } from '~/composables/useRouteLocationWithLocale'
import { ROUTE_NAMES } from '~/scopes/dashboard/constants'

import translations from './Breadcrumb.translations'

const i18n = useI18n()
const resolveLocalizedRoute = useRouteLocationWithLocale()
</script>
